




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/vessel-groups/vessel-groups";

@Component
export default class VesselGroupsWrapper extends Vue {
  instance: any = {};

  mounted(): void {
    this.instance = new koHtmlAndViewModel.viewModel();
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;
    ko.applyBindings(this.instance, this.$el);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
