






import { Component, Vue } from "vue-property-decorator";
import vesselGroupsWrapper from "@/components/knockoutWrappers/VesselGroupsWrapper.vue";
import events from "App/events";
import { SnackbarConfig } from "@/types/snackbarConfig";
import htmlEncoder from "Utilities/htmlencode-helper";

@Component({
  components: {
    vesselGroupsWrapper,
  },
})
export default class VesselGroups extends Vue {
  vesselGroupCreatedBinding: any = null;
  vesselGroupDeletedBinding: any = null;
  vesselGroupUpdatedBinding: any = null;

  message(action: string, vesselGroupName: string): Partial<SnackbarConfig> {
    let text = `Vessel group '${htmlEncoder.htmlEncode(vesselGroupName)}' has been `;
    switch (action) {
      case "createdVesselGroup":
        text += "<b>created</b>";
        break;

      case "updatedVesselGroup":
        text += "<b>updated</b>";
        break;

      case "deletedVesselGroup":
        text += "<b>deleted</b>";
        break;
    }

    return {
      text: text,
      color: "success",
    };
  }

  mounted(): void {
    this.vesselGroupCreatedBinding = events.vesselGroupCreated.add((createdVesselGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("createdVesselGroup", createdVesselGroup.name));
    });

    this.vesselGroupUpdatedBinding = events.vesselGroupUpdated.add((createdVesselGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("updatedVesselGroup", createdVesselGroup.name));
    });

    this.vesselGroupDeletedBinding = events.vesselGroupDeleted.add((createdVesselGroup: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message("deletedVesselGroup", createdVesselGroup.name));
    });
  }

  beforeDestroy(): void {
    this.vesselGroupCreatedBinding.detach();
    this.vesselGroupUpdatedBinding.detach();
    this.vesselGroupDeletedBinding.detach();
  }
}
